import Mock from "../mock";

const database = {
  information: {
    name: "Joseph Beck Castro",
    aboutContent:
      "I am a web developer. I specialize in React/MERN Stacks and static HTML & CSS pages",
    // age: 36,
    phone: "",
    // nationality: "American",
    // language: "English",
    email: "joe@josephbeckcastro.com",
    address: "Seattle, WA",
    freelanceStatus: "Available",
    socialLinks: {
      // facebook: 'https://facebook.com',
      // twitter: 'https://twitter.com',
      // pinterest: '',
      // behance: '',
      linkedin: "https://www.linkedin.com/in/joseph-beck-castro/",
      dribbble: "",
      github: "https://github.com/jbcastro",
    },
    brandImage: "/images/jbclogo.png",
    aboutImage: "/images/jbcastro.png",
    aboutImageLg: "/images/about-image-lg.jpg",
    cvfile: "/files/resume.pdf",
  },
  services: [
    {
      title: "Web Design",
      icon: "color-pallet",
      details:
        "I can create web sites using HTML, CSS, and JavaScript either from scratch or with a template",
    },
    {
      title: "Web Development",
      icon: "code",
      details:
        "Web apps with either a NoSQL or SQL backend, with React or HTML and CSS",
    },
    // {
    //   title: "Mobile Application",
    //   icon: "mobile",
    //   details:
    //     "Lorem ipsum dolor sit amet consectetur adipisicing elit. Autem tenetur ratione quod.",
    // },
  ],
  reviews: [
    {
      id: 1,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam, aliquid maxime tempora.",
      author: {
        name: "Aaron Frescas",
        designation: "Client",
      },
    },
    {
      id: 2,
      content:
        "Lorem ipsum dolor, sit amet consectetur adipisicing elit. Expedita impedit nobis tempore quaerat quibusdam.",
      author: {
        name: "Jim Provance",
        designation: "Client",
      },
    },
  ],
  skills: [
    {
      title: "HTML5",
      value: 95,
    },
    {
      title: "CSS3",
      value: 90,
    },
    {
      title: "Javascript",
      value: 90,
    },
    {
      title: "jQuery",
      value: 80,
    },
    {
      title: "ReactJS",
      value: 90,
    },
    // {
    //   title: "Photoshop",
    //   value: 65
    // }
  ],
  portfolios: [
    {
      id: 1,
      title: "Wine Training App",
      subtitle: "MERN App Wine Training for Restaurant Employees",
      imageUrl: "/images/btg.jpg",
      largeImageUrl: ["/images/btg_large.jpg"],
      url: "https://github.com/jbcastro/btg_matrix",
      //insert youtube link here
    },

    {
      id: 3,
      title: "Pokedex React App",
      subtitle: "Pokedex with API Calls",
      imageUrl: "/images/pokedex.jpg",
      largeImageUrl: ["/images/pokedex_large.jpg"],
      url: "https://github.com/jbcastro/pokedex",
    },
    {
      id: 4,
      title: "Bingo Game",
      subtitle: "Quick React bingo game I made in a day from scratch",
      imageUrl: "/images/bingo.jpg",
      largeImageUrl: ["/images/bingo_large.jpg"],
      url:"https://github.com/jbcastro/bingo"
    },
    
    {
      id: 5,
      title: "Speakeasy Website",
      subtitle: "Website for Speakesy Comedy Show with SEO made from scratch. Current site is down so backup linked with outdated info",
      imageUrl: "/images/speakeasy.jpg",
      largeImageUrl: ["/images/speakeasy_large.jpg"],
      url: "https://www.react.josephbeckcastro.com/speakeasy/index.html",
    },
    {
      id: 6,
      title: "Github",
      subtitle: "A Link to my Github",
      imageUrl: "/images/github.jpg",
      largeImageUrl: ["/images/github_large.jpg"],

      url: "https://github.com/jbcastro/",
    },
    {
      id: 7,
      title: "Personal Trainer Website",
      subtitle: "Website for Personal Trainer with SEO Made with Template",
      imageUrl: "/images/jumpstart.jpg",
      largeImageUrl: ["/images/jumpstart_large.jpg"],
      url: "https://www.jumpstartpersonaltraining.com/",
    },
    {
      id: 8,
      title: "My Podcast Site",
      subtitle: 'First time using WordPress I made a site for my podcast "See You At The Poddy, Richter!"',
      imageUrl: "/images/eraser.jpg",
      largeImageUrl: ["/images/eraser-lg.jpg"],
      url:"https://www.thepoddy.net/"
    },
    {
      id: 9,
      title: "Dog Haircut Site with jQuery",
      subtitle: "A site I made for School with jQuery, HTML, and CSS",
      imageUrl: "/images/crazydogs.jpg",
      largeImageUrl: ["/images/crazydogs_large.jpg"],
      url: "https://josephbeckcastro.com/web150/final/index.html",
    },
    
    
    {
      id: 10,
      title: "Old Portfolio Page",
      subtitle: "Previous portfolio made with HTML and CSS from scratch",
      imageUrl: "/images/old.jpg",
      largeImageUrl: ["/images/old_lg.jpg"],
      url: 'https://www.react.josephbeckcastro.com/old/index.html'
    },
    // {
    //   id: 11,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 12,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 13,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: 'https://dribbble.com'
    // },
    // {
    //   id: 14,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // },
    // {
    //   id: 15,
    //   title: "Pendrive",
    //   subtitle: "Free pendrive mockup design.",
    //   imageUrl: "/images/portfolio-image-6.jpg",
    //   largeImageUrl: ["/images/portfolio-image-6-lg.jpg"]
    // },
    // {
    //   id: 16,
    //   title: "Beautiful Pendrive",
    //   subtitle: "Pendrive with great design & flexible.",
    //   imageUrl: "/images/portfolio-image-7.jpg",
    //   largeImageUrl: ["/images/portfolio-image-7-lg.jpg"],
    //   url: 'https://twitter.com'
    // },
    // {
    //   id: 17,
    //   title: "Sticker",
    //   subtitle: "Clip sticker mockup design.",
    //   imageUrl: "/images/portfolio-image-8.jpg",
    //   largeImageUrl: ["/images/portfolio-image-8-lg.jpg"]
    // },
    // {
    //   id: 18,
    //   title: "Packet",
    //   subtitle: "Beautiful packet & product design.",
    //   imageUrl: "/images/portfolio-image-9.jpg",
    //   largeImageUrl: ["/images/portfolio-image-9-lg.jpg"]
    // },
    // {
    //   id: 19,
    //   title: "T-shirt Mockup",
    //   subtitle: "A beautiful t-shirt mockup.",
    //   imageUrl: "/images/portfolio-image-1.jpg",
    //   largeImageUrl: ["/images/portfolio-image-1-lg.jpg"],
    //   url: 'https://dribbble.com'
    // },
    // {
    //   id: 20,
    //   title: "Coffee Mug",
    //   subtitle: "Awesome coffee mug design.",
    //   imageUrl: "/images/portfolio-image-4.jpg",
    //   largeImageUrl: [
    //     "/images/portfolio-image-4-lg.jpg",
    //     "/images/portfolio-image-4-lg2.jpg"
    //   ],
    //   url: 'https://facebook.com'
    // },
    // {
    //   id: 21,
    //   title: "Tea & Coffee Mug",
    //   subtitle: "Beautiful mug with logo.",
    //   imageUrl: "/images/portfolio-image-2.jpg",
    //   url: 'https://pinterest.com'
    // },
    // {
    //   id: 22,
    //   title: "Pen Holder",
    //   subtitle: "A pen holder with beautiful design.",
    //   imageUrl: "/images/portfolio-image-3.jpg",
    //   largeImageUrl: ["/images/portfolio-image-3-lg.jpg"],
    //   url: '#'
    // },
    // {
    //   id: 23,
    //   title: "Mug",
    //   subtitle: "Mug with awesome style",
    //   imageUrl: "/images/portfolio-image-5.jpg",
    //   largeImageUrl: ["/images/portfolio-image-5-lg.jpg"]
    // }
  ],
  experience: {
    workingExperience: [
      {
        id: 1,
        year: "2018 - Present",
        position: "Frontend Web Developer",
        company: "Freelance",
        details:
          "Creating/updating sites and apps for clients and businesses, both front end and back end. Plan website development, converting mockups into usable web presence with HTML, CSS, JavaScript, React, Node.JS, and Express coding. Constant contact with clients to ensure their requests are met. Deploy sites and apps to Heroku, AWS, Netlify, and Github Pages. Create and deployed APIs.",
      },
      {
        id: 2,
        year: "2020 - Present",
        position: "Field Technician I",
        company: "Compucom",
        details:
          "Wide range of cases including software support, networking, POS systems, printer, and much more. Work independently setting up queues, escalating cases, and traveling. Keep meticulous notes and records about what was performed and at what time. Received a '5C Culture Ambassador Award' for 'demonstrating exceptional focus and commitment' including 'Understanding What The Customer Wants And Needs. Keeping Promises. Identifying And Resolving Friction Points. Delivering To Exceed Expectations. Getting Feedback And Acting On It.' ",
      },
      // {
      //   id: 3,
      //   year: "2014 - 2020",
      //   position: "UI/UX Designer",
      //   company: "Example Company",
      //   details: "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quas, magni mollitia, aspernatur consequatur accusamus vero eum facere exercitationem velit suscipit ipsam placeat libero. Deleniti exercitationem nostrum quasi. Molestiae, vel porro."
      // }
    ],
    educationExperience: [
      {
        id: 1,
        year: "2018 - 2018",
        graduation:
          "Database Development and Admintration, Web Scripting Certificate",
        university: "Seattle Central College",
        details:
          "Focus on Database with SQL, SQL Server, and NoSql. Classes included front end and backend JavaScript, .NET, and web design. Dean's List and President's List.",
      },
      {
        id: 2,
        year: "2014 - 2018",
        graduation: "IT Support Specialist Certificate",
        university: "South Seattle College",
        details:
          "Focus on A+ certification but also including programming fundamentals, Linux/Unix operating systems, version control (Git Bash/Hub etc). Honor Roll and Dean's List.",
      },
      {
        id: 3,
        year: "2006 - 2010",
        graduation: "Bachelor of Arts, Psychology",
        university: "San Francisco State University",
        details: "Focus on Childhood Development.",
      },
    ],
  },
  blogs: [
    {
      id: 1,
      title: "Markdown & Html supported blog.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/markdown-html-supported-blog.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 2,
      title: "Installing NodeJS on your device.",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/installing-nodejs-on-your-device.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 3,
      title: "UI/UX design starter with Adobe XD.",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/uiux-design-starter-with-adobe-xd.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 4,
      title: "Boost your post for increasing sales.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/boost-your-post-for-increasing-sales.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 5,
      title: "Difference between GatsbyJS & NextJS.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/difference-between-gatsbyjs-and-nextjs.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 6,
      title: "How to choose javascript framework for project.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource:
        "../../blog/how-to-choose-javascript-framework-for-project.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 7,
      title: "Web automation with python language.",
      featuredImage: "/images/blog-image-7.jpg",
      filesource: "../../blog/web-automation-with-python-language.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 8,
      title: "Time to use latest technology for creating a website.",
      featuredImage: "/images/blog-image-8.jpg",
      filesource:
        "../../blog/time-to-use-latest-technology-for-creating-a-website.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 9,
      title: "Think out of the box.",
      featuredImage: "/images/blog-image-9.jpg",
      filesource: "../../blog/think-out-of-the-box.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 10,
      title: "Trending designs in 2020.",
      featuredImage: "/images/blog-image-1.jpg",
      filesource: "../../blog/trending-designs-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 11,
      title: "How to get 10k instagram followers?",
      featuredImage: "/images/blog-image-2.jpg",
      filesource: "../../blog/how-to-get-10k-instagram-followers.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 12,
      title: "What NodeJS can do?",
      featuredImage: "/images/blog-image-3.jpg",
      filesource: "../../blog/what-nodejs-can-do.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 13,
      title: "Futures of javascript.",
      featuredImage: "/images/blog-image-4.jpg",
      filesource: "../../blog/future-of-javascript.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 14,
      title: "Popular javascript library in 2020.",
      featuredImage: "/images/blog-image-5.jpg",
      filesource: "../../blog/popular-javascript-library-in-2020.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
    {
      id: 15,
      title: "Promrammers must read books.",
      featuredImage: "/images/blog-image-6.jpg",
      filesource: "../../blog/programmers-must-read-books.md",
      createDay: "20",
      createMonth: "February",
      createYear: "2020",
    },
  ],
  contactInfo: {
    phoneNumbers: ["415-669-4898"],
    emailAddress: ["joe@josephbeckcastro.com"],
    address: "Seattle, WA",
  },
};

Mock.onGet("/api/information").reply((config) => {
  const response = database.information;
  return [200, response];
});

Mock.onGet("/api/services").reply((config) => {
  const response = database.services;
  return [200, response];
});

Mock.onGet("/api/reviews").reply((config) => {
  const response = database.reviews;
  return [200, response];
});

Mock.onGet("/api/skills").reply((config) => {
  const response = database.skills;
  return [200, response];
});

Mock.onGet("/api/portfolios").reply((config) => {
  const response = database.portfolios;
  return [200, response];
});

Mock.onGet("/api/experience").reply((config) => {
  const response = database.experience;
  return [200, response];
});

Mock.onGet("/api/blog").reply((config) => {
  const response = database.blogs;
  return [200, response];
});

Mock.onGet("/api/contactinfo").reply((config) => {
  const response = database.contactInfo;
  return [200, response];
});
